<template>
  <div class="vap-page">
    <Loading
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>{{ schedulerTitle }}</h1>
      <div class="btns-container">
        <VasionButton
          id="save-button"
          name="save-button"
          classProp="primary"
          :isDisabled="disableSaveButton"
          @vasionButtonClicked="save()"
        >
          Save
        </VasionButton>

        <VasionButton
          id="cancel-button"
          class="last-btn"
          name="cancel-button"
          classProp="secondary"
          @vasionButtonClicked="cancel()"
        >
          Cancel
        </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div id="import-details">
        <AutomationEngineSharedHeader
          ref="header"
          :nameLabel="'IMPORT NAME'"
          @permissions="showMainSection = false"
          @general-automation="showMainSection = true"
          @dirty="setIsDirty()"
        />

        <!-- Build XML Import -->
        <div v-if="showMainSection" id="build-xml-import" class="collapsable-section">
          <h2 class="subheader">
            Build XML Import
          </h2>

          <VasionButton
            name="collapse-section"
            class="collapse-section"
            :icon="showBuildImport ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
            title="Collapse Section"
            @vasionButtonClicked="toggleCollapsableSection('build-xml-import')"
          />

          <div v-show="showBuildImport">
            <div class="row">
              <VasionDropList
                class="row-field"
                v-slot="slotItem"
                v-model="importFromFolderType"
                :required="true"
                :dataArray="importFromValues"
                :title="'IMPORT FROM'"
                :type="'plain-list'"
                :valueName="'name'"
                displayName="name"
                :width="'396'"
                @input="setIsDirty();"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
              
              <div
                v-if="isVasionFolder || isCloudStorage"
                class="row continue"
              >
                <VasionInput
                  id="import-to"
                  v-model="importFromFolderName"
                  class="row-field browse-input"
                  title="FOLDER"
                  inputType="top-white"
                  name="import-to"
                  placeholder="Select Folder..."
                  :required="true"
                  :readonly="true"
                  :width="'396'"
                />
                <VasionButton
                  id="import-to-folder-button"
                  class="browse-button"
                  :classProp="'primary'"
                  @vasionButtonClicked="toggleBrowseFolderDialog('importFrom')"
                >
                  Browse
                </VasionButton>
              </div>
              <div v-else-if="isExternalStorage">
                <VasionInput
                  v-model="importFromFolderPath"
                  class="row-field browse-input"
                  title="FOLDER"
                  inputType="top-white"
                  name="import-to"
                  placeholder="Enter Folder..."
                  :required="true"
                  :width="'396'"
                  @input="setIsDirty"
                />
              </div>
            </div>

            <div class="row">
              <VasionInput
                id="import-to"
                v-model="importToFolderName"
                class="row-field browse-input"
                title="IMPORT TO"
                inputType="top-white"
                name="import-to"
                placeholder="Select Folder..."
                :required="true"
                :readonly="true"
                :width="'396'"
              />
              <VasionButton
                id="import-to-folder-button"
                class="browse-button"
                :classProp="'primary'"
                @vasionButtonClicked="toggleBrowseImportToFolderDialog"
              >
                Browse
              </VasionButton>
            </div>
            
            <div class="row">
              <VasionDropList
                class="row-field"
                v-slot="slotItem"
                v-model="formId"
                :required="true"
                :dataArray="configurationValues"
                :title="'CONFIGURATION NAME'"
                :type="'plain-list'"
                :valueName="'name'"
                displayName="name"
                width="396"
                @input="newDocumentNameAttribute(true)"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </div>

            <div class="row">
              <VasionDropList
                class="row-field"
                :isDisabled="performDocumentNameAttribute"
                v-slot="slotItem"
                v-model="indexFieldId"
                :dataArray="indexFieldValues"
                :title="'BATES LABEL FIELD'"
                :type="'plain-list'"
                :valueName="'value'"
                displayName="name"
                :width="'396'"
                @input="setIsDirty"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </div>

            <div class="row">
              <VasionDropList
                id="workflow-field"
                v-slot="slotItem"
                v-model="selectedWorkflow"
                title="WORKFLOW"
                name="workflow-field"
                type="plain-list"
                :dataArray="workflowListValues"
                :width="'396'"
                valueName="value"
                displayName="name"
                placeholder=""
                @input="setIsDirty"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </div>

            <div class="row">
              <VasionCheckbox
                id="static-bates-label"
                name="static-bates-label"
                class="import-checkbox"
                :checked="performDocumentNameAttribute"
                :width="'100'"
                @change="toggleCheckbox('performDocumentNameAttribute')"
              >
                Static Bates Label
              </VasionCheckbox>
            </div>
            <div class="row">
              <VasionInput
                id="xml-static-bates-label"
                v-model="xmlStaticLabel"
                class="row-field"
                title=""
                inputType="top-white"
                name="xml-static-bates-label"
                placeholder="Enter Static Bates Label..."
                :width="'396'"
                :isDisabled="!performDocumentNameAttribute"
              />
            </div>

            <div class="row">
              <VasionCheckbox
                id="delete-original-documents"
                name="delete-original-documents"
                class="import-checkbox"
                :checked="deleteFilesUponImport"
                @change="toggleCheckbox('deleteFilesUponImport')"
              >
                Delete Original Documents
              </VasionCheckbox>
            </div>

            <div class="row">
              <VasionCheckbox
                id="ocr-documents"
                name="ocr-documents"
                class="import-checkbox"
                :checked="ocrDocument"
                @change="toggleCheckbox('ocrDocument')"
              >
                OCR Documents
              </VasionCheckbox>
            </div>
          </div>
        </div>
      </div>
    </div>

    <md-dialog id="browse-folders-dialog" :md-active.sync="showBrowseImportToFoldersDialog" :md-click-outside-to-close="false">
      <VasionFolderSelect
        title="Select 'Import To' Folder..."
        okButtonText="Select"
        :showOnlyDataAutomation="false"
        @cancelButtonClick="showBrowseImportToFoldersDialog = false"
        @okButtonClick="acceptImportToFolderDetails"
      />
    </md-dialog>

    <md-dialog :md-active.sync="showBrowseFoldersDialog" :md-click-outside-to-close="false">
      <VasionFolderSelect
        okButtonText="Select"
        :title="browseModalTitle"
        :showOnlyDataAutomation="importFromFolderType.name === 'Cloud Storage'"
        @cancelButtonClick="toggleBrowseFolderDialog(lastClickedBrowseButton)"
        @okButtonClick="folderDialogOK"
      />
    </md-dialog>

    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="cancelLeave()">
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>

    <VasionSnackbar
      id="import-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
/* eslint-disable valid-typeof */
/* eslint-disable no-mixed-operators */

import AutomationEngineSharedHeader from '@/views/admin/automationEngine/AutomationEngineSharedHeader.vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import VasionButton from '../../../components/shared/VasionButton.vue';

export default {
  name: 'EditXMLImport',
  components: {
    AutomationEngineSharedHeader,
    Loading,
    VasionButton,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  data: function () {
    return {
      configurationValues: [],
      deleteDocumentFileImport: false,
      deleteFilesUponImport: false,
      folderId: null,
      importFromValues: [
        {name: 'Cloud Storage'},
        {name: 'External Source'},
      ],
      xmlImportId: 0,
      importFromFolderType: {},
      importFromFolderId: '',
      importFromFolderName: '',
      importFromStorageConfigId: 0,
      importToFolderId: 0,
      importToFolderName: '',
      indexFieldId: {},
      indexFieldValues: [],
      isDirty: false,
      isEditMode: false,
      isLoading: false,
      lastClickedBrowseButton: '',
      loaderBackgroundColor,
      loaderColor,
      masterFieldList: [],
      ocrDocument: false,
      formId: {},
      schedulerTitle: '',
      selectedWorkflow: {},
      showBrowseFoldersDialog: false,
      showBrowseImportToFoldersDialog: false,
      showBuildImport: true,
      showLeaveDialog: false,
      showMainSection: true,
      showSnackbarBool: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      performDocumentNameAttribute: false,
      workflowList: [],
      xmlStaticLabel: '',
    }
  },
  computed: {
    activeScheduler() { return this.$store.state.automationEngine.activeScheduler },
    browseModalTitle() {
      if (this.lastClickedBrowseButton === 'importFrom') return 'Select Import Folder'
      if (this.lastClickedBrowseButton === 'completedFolder') return 'Select Completed Folder'
      return ''
    },
    disableSaveButton() { return this.saveLocked || !this.isDirty },
    isCloudStorage() { return this.importFromFolderType.name === 'Cloud Storage' },
    isExternalStorage() { return this.importFromFolderType.name === 'External Source' },
    isVasionFolder() { return this.importFromFolderType.name === 'Vasion Folder' },
    mappingButtonText() { return this.uploadedCSVFileString === '' ? "View Mapping" : "Configure Mapping" },
    workflowListValues() {
      const workflows = this.workflowList.map(a => {
        return {
          name: a.sName,
          value: a.iID,
        }
      })
      workflows.unshift({ name: '', value: 0 })
      return workflows
    },
  },
  async created() {
    this.isLoading = true

    const promises = await Promise.all([
      this.$store.dispatch('automationEngine/clearActiveScheduler'),
      this.$store.dispatch('admin/getXmlConfigurationList'),
      this.$store.dispatch('workflow/getWorkflowMenuItems'),
    ])
    this.workflowList = promises[2]
    const configurations = promises[1].configurations

    this.masterFieldList.push({ configId: 0, name: '', value: 0 })
    if (configurations) {
      this.configurationValues = configurations.map(config => {
        config.fields.map(field => {
          if (field.parameter !== '') {
            this.masterFieldList.push({
              configId: Number(config.id),
              name: field.parameter,
              value: field.indexField,
            })
          }
        })

        return {
          name: config.name,
          value: Number(config.id),
        };
      });
    }
    this.indexFieldValues = this.masterFieldList.filter(f => f.configId === 0)

    if (this.$route.params.schedulerId && parseInt(this.$route.params.schedulerId, 10) > 0) {
      await this.$store.dispatch('automationEngine/getXmlImportScheduler', parseInt(this.$route.params.schedulerId, 10))
      if (this.activeScheduler) {
        await this.setImportValues()
      }
    }
    if (!this.importFromFolderType.name) {
      this.importFromFolderType = { name: 'Cloud Storage' }
    }
    await this.setHeaderDetails(this.xmlImportId, this.activeScheduler?.runDetails)

    this.schedulerTitle = this.xmlImportId && this.xmlImportId > 0 && this.activeScheduler.runDetails ? this.activeScheduler.runDetails.name : 'Untitled XML Import'

    this.isLoading = false
    this.isDirty = false
  },
  methods: {
    acceptImportToFolderDetails(selectedFolder) {
      this.isDirty = true
      this.importToFolderId = selectedFolder.SelectedFolderID
      this.importToFolderName = selectedFolder.SelectedFolderName
      this.showBrowseImportToFoldersDialog = false
    },
    cancel() { this.$router.push({ name: 'XMLImport' }) },
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    async doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ path: this.routeTo.path })
    },
    folderDialogOK(payload) {
      this.setIsDirty()
      this.importFromFolderId = payload.SelectedFolderID
      this.importFromFolderName = payload.SelectedFolderName
      this.importFromStorageConfigId = payload.SelectedDriveType.storageConfigId

      this.toggleBrowseFolderDialog(this.lastClickedBrowseButton)
    },
    async newDocumentNameAttribute(clearField) {
      this.isDirty = true
      this.indexFieldValues = this.masterFieldList.filter(f => f.configId === 0 || f.configId === this.formId.value)
      if (clearField)
        this.indexFieldId = this.indexFieldValues.find(f => f.configId === 0)
    },
    async save() {
      this.saveLocked = true
      const { header } = this.$refs
      if (await this.validate(header)) {
        const payload = {
          runDetails: await header.getValues(),
          folderDatPath: (this.isExternalStorage) ? this.importFromFolderPath: '',
          importFromFolderName: (this.isCloudStorage) ? this.importFromFolderName : '',
          importFromStorageConfigId: (this.isCloudStorage) ? this.importFromStorageConfigId : 0,
          importFromThirdPartyFolderId: (this.isCloudStorage) ? this.importFromFolderId : '',
          importToFolderID: this.importToFolderId,
          importToFolderName: this.importToFolderName,
          workflowID: (this.selectedWorkflow.value !== undefined) ? this.selectedWorkflow.value : null,
          performDocumentNameAttribute: this.performDocumentNameAttribute,
          deleteFilesUponImport: this.deleteFilesUponImport,
          ocrDocument: this.ocrDocument,
          xmlStaticLabel: (this.performDocumentNameAttribute) ? this.xmlStaticLabel : '',
          indexFieldId: (this.indexFieldId !== undefined && !this.performDocumentNameAttribute) ? this.indexFieldId.value : 0,
          formId: this.formId.value,
        }

        if (!await this.$store.dispatch('automationEngine/saveXMLImportScheduler', payload)) {
          this.snackbarTitle = 'ERROR'
          this.snackbarSubTitle = 'There was an error saving the import.'
          this.snackbarImage = false
          this.saveLocked = false
        } else {
          this.isDirty = false
          this.snackbarTitle = 'SUCCESS'
          this.snackbarSubTitle = 'Import saved successfully.'
          this.snackbarImage = true
          this.saveLocked = false

          this.$router.push({ name: 'XMLImport' })
        }
        this.showSnackbarBool = true
      }
    },
    async setHeaderDetails(id, details) {
      const { header } = this.$refs
      if (id > 0) {
        await header.setValues(details)
      } else {
        await header.setValues()
      }
    },
    async setImportValues() {
      this.xmlImportId = this.activeScheduler.runDetails.schedulerID
      if (this.activeScheduler.folderDatPath) {
        this.importFromFolderPath = this.activeScheduler.folderDatPath
        this.importFromFolderType = {name: 'External Source'}
      } else if (this.activeScheduler.importFromThirdPartyFolderId) {
        this.importFromFolderId = this.activeScheduler.importFromThirdPartyFolderId
        this.importFromFolderName = this.activeScheduler.importFromFolderName
        this.importFromStorageConfigId = this.activeScheduler.importFromStorageConfigId
        this.importFromFolderType = {name: 'Cloud Storage'}
      }
      this.importToFolderName = this.activeScheduler.importToFolderName
      this.importToFolderId = this.activeScheduler.importToFolderID
      this.formId = this.configurationValues.find(c => c.value === this.activeScheduler.formId)
      await this.newDocumentNameAttribute(false)
      this.indexFieldId = this.indexFieldValues.find(f => f.value === this.activeScheduler.indexFieldId)
      if (this.activeScheduler.workflowID) {
        this.selectedWorkflow = this.workflowListValues.find(w => w.value === this.activeScheduler.workflowID)
      }
      this.performDocumentNameAttribute = this.activeScheduler.xmlStaticLabel ? true : false
      this.xmlStaticLabel = this.activeScheduler.xmlStaticLabel
      this.deleteFilesUponImport = this.activeScheduler.deleteFilesUponImport
      this.ocrDocument = this.activeScheduler.ocrDocument

      this.isEditMode = true
    },
    setIsDirty() {
      this.isDirty = true
    },
    setMappingConfiguration(value) {
      this.setIsDirty()
      this.mappingConfiguration = value
    },
    toggleBrowseFolderDialog() { this.showBrowseFoldersDialog = !this.showBrowseFoldersDialog },
    toggleBrowseImportToFolderDialog() { this.showBrowseImportToFoldersDialog = !this.showBrowseImportToFoldersDialog },
    toggleCheckbox(id) {
      this[id] = !this[id]
      this.setIsDirty()
    },
    toggleCollapsableSection(section) {
      switch (section) {
        case 'build-xml-import':
          this.showBuildImport = !this.showBuildImport
          break;
        default:
          this.showBuildImport = false
          break;
      }
    },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
    async validate(header) {
      let errorText = ''
      this.snackbarTitle = 'ERROR'
      this.snackbarImage = false

      await header.validate()
      if (header.errors !== '') {
        errorText += `${header.errors}`
      }
      
      if (this.isCloudStorage && this.importFromFolderId === '') {
        errorText += '- Please provide a value for "Import From" Folder.\n'
      } else if (this.isExternalStorage) {
        if (!await this.$store.dispatch('automationEngine/getIsValidUncPath', { Value: this.importFromFolderPath }))
          errorText += '- The value of "Import From" Folder is not a valid UNC path.\n'
      }

      if (this.importToFolderId === 0) 
        errorText += '- Please provide a value for "Import To" Folder.\n'

      if (this.formId.value === undefined)
        errorText += '- Please select a Configuration Name.\n'

      if (errorText !== '') {
        this.snackbarSubTitle = errorText
        this.showSnackbarBool = true
        console.warn(errorText)
        this.saveLocked = false
        return false
      }

      return true
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  .browse-button {
    padding-top: 15px;
  }

  .trash-can-padding {
    padding-top: 20px;
  }

  #import-details {
    width: 100%;
    .row {
      width: 100%;
      margin: 13px 0;
      display: flex;

      .continue {
        width: 50%;
        margin : 0 0;
      }

      .subheader {
        @include SubHeadline;
      }

      .row-field {
        margin: 0 14px 5px 0;
      }

      .browse-input {
        margin-right: 5px;
      }

      .import-checkbox {
        width: 396px;
      }
    }

    .collapsable-section {
      float: left;
      margin-top: 20px;
      width: 100%;

      .subheader {
        @include SubHeadline;
        width: 225px;
        display: block;
        float: left;
        margin-top: 5px;
      }
    }
  }
</style>
